<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 padding-l-13">我的发票</div>
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">
					<span class="iconfont icon-biaodan xinzengIcon color-theme"></span>
					<span> {{pageTitle}}发票信息</span>
				</div>
				<el-form-item label="发票类型" prop="fInvoiceClassID">
					<el-select v-model="addCarForm.fInvoiceClassID" placeholder="请选择发票类型" disabled>
						<el-option v-for="item in invoiceTypeList" :key="item.fInvoiceClassID" :label="item.fInvoiceClassName" :value="item.fInvoiceClassID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="发票抬头" prop="fUnitName">
					<el-input v-model="addCarForm.fUnitName" placeholder="请输入发票抬头" disabled></el-input>
				</el-form-item>
				<el-form-item label="企业税号" prop="fTaxAccounts">
					<el-input v-model="addCarForm.fTaxAccounts" placeholder="请输入企业税号" disabled></el-input>
				</el-form-item>
				<el-form-item label="注册地址" prop="fAddress">
					<el-input v-model="addCarForm.fAddress" placeholder="请输入注册地址" disabled></el-input>
				</el-form-item>
				<el-form-item label="公司电话" prop="fPhone">
					<el-input v-model="addCarForm.fPhone" placeholder="请输入公司电话"></el-input>
				</el-form-item>
				<el-form-item label="开户银行" prop="fBankNames">
					<el-input v-model="addCarForm.fBankNames" placeholder="请输入开户银行"></el-input>
				</el-form-item>
				<el-form-item label="银行账号" prop="fBankAccounts">
					<el-input v-model="addCarForm.fBankAccounts" placeholder="请输入银行账号"></el-input>
				</el-form-item>
				<el-form-item v-for="(item,index) of uploadRule" :key="index" :label="item.rule.fRuleName">
					<div class="padding-15 border-F2F2F2">
						<UploadImgList 
							ref="uploadImg"
							:accept="limitTypeArr" 
							:rules="item.rule" 
							:uploadToken="uploadToken[index]" 
							:limit="limit" 
							v-model="annexEntityArray[index]"
							uploadType="image" />
					</div>
					<div class="font-size12 font-color-F56C6C margin-t-4" style="line-height: normal;" v-if="limitTypeArr[index]||item.rule.fMaxSize||item.rule.fMaxWidth||item.rule.fMaxHeight">
						(提示：
						<span>格式为{{item.rule.fFileType}}</span>
						<span v-if="item.rule.fMaxSize">,大小不能超过{{ item.rule.fMaxSize }}mb</span>
						<span v-if="item.rule.fMaxWidth">,宽度不能超过{{ item.rule.fMaxWidth }}px</span>
						<span v-if="item.rule.fMaxHeight">,高度不能超过{{ item.rule.fMaxHeight }}px</span>
						)
					</div>
				</el-form-item>
				<el-form-item>
					<el-button class="color-theme border-theme" @click="cancleForm()">取消</el-button>
					<el-button class="background-color-theme" @click="submitForm('addCarForm')">提交审核</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="bg-box"></div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import UploadImgList from "@/components/upload/UploadImgList";
	export default {
		name: "SelectArea",
		props: {
			value: Array
		},
		data() {
			const rulesTelephone = (rule, value, callback) => {
				if (!/^1[34578]\d{9}$/.test(value)) {
					callback('手机号不正确')
				} else {
					callback()
				}
			}
			return {
				imgObj: '',
				fInvoiceInfoID: "",
				fAnnexID: 0,
				fRuleID: 0,
				fAnnexTypeID: 0,
				//上传图片
				limit:1,
				uploadRule: [], //上传图片规则
				uploadToken: [], //上传图片token
				limitTypeArr: '', //上传图片格式规则
				annexEntityArray: [],//上传图片绑定的数组
				// 标题乳片规则
				titlePicOPath: [],
				invoiceTypeList: [],
				pageTitle: "新增发票表单信息",
				getYZ: "获取验证码",
				miao: '',
				dialogImageUrl: '',
				dialogVisible: false,
				addCarForm: {
					fInvoiceClassID: '', //发票类型id
					fUnitName: "", //发票抬头
					fTaxAccounts: "", //	企业税号
					fAddress: "", //注册地址
					fPhone: "", //公司电话
					fBankNames: "", // 开户行
					fBankAccounts: "", // 银行账号
					fGovernmenAreaID: '', //地址id
					fLinkman: "", //纳税人资质	
					fIsSubmit: 0,
					annexes: [{
						fAnnexID: '',
						fRuleID: '',
						fAnnexTypeID: '',
						fPath: '',
						fSourceFileName: '',
						fFileExName: ''
					}]
				},
				rules: {
					fPhone: [{
						required: true,
						message: '请输入电话号码',
						trigger: 'blur'
					},{
						validator: rulesTelephone,
						trigger: 'blur'
					}],
					fBankNames:[{
						required: true,
						message: '请输入开户行信息',
						trigger: 'blur'
					}],
					fBankAccounts: [{
						required: true,
						message: '银行账号不能为空',
						trigger: 'blur'
					}, {
						min: 13,
						max: 19,
						message: '银行账号13~19位',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^([1-9]{1})(\d{12}|\d{13}|\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/,
						// /^0(\d{2,3}|\d{2,3}-)?\d{7,8}$/
						message: '请输入正确的银行账号',
						trigger: 'blur'
					}],
					fIsNecessary: [{
						required: true,
						message: '请上传纳税资质图片'
					}],
				},
				oldfIsNecessary:[],
				nowfIsNecessary:[],
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			UploadImgList
		},
		mounted() {
			this.getInvoiceType();
			this.getInvoiceList();
			if (this.$route.query.pageTitle == 3) {
				this.pageTitle = "变更"
			}
		},
		methods: {
			//发票类型
			getInvoiceType() {
				this.ApiRequestPost('/api/mall/ebbase/invoice-class/get-onstatus-list', {}).then(
					result => {
						//console.log(result);
						this.invoiceTypeList = result.obj.items;
					},
					rej => {}
				);

			},
			//发票信息
			getInvoiceList() {
				this.ApiRequestPost('/api/mall/ebcustomer/invoice-info/get', {}).then(
					result => {
						console.log(result,"result");
						this.addCarForm = result.obj;
						this.uploadRule = result.obj.rules;
						this.annexEntityArray = [];
						this.oldfIsNecessary = [];
						result.obj.rules.forEach((item)=>{
							if(item.rule.fIsNecessary == 1){
								this.oldfIsNecessary.push(item.rule.fAnnexTypeID);
							}
						});
						if(result.obj.annexes.length<result.obj.rules.length){
							this.annexEntityArray.push([{fPath:''}]);
							console.log('min');
						}						
							result.obj.rules.forEach((item)=>{
							   if(result.obj.annexes.some(p=>p.fAnnexTypeID == item.rule.fAnnexTypeID)){
								   var annexe=result.obj.annexes.find(p=>p.fAnnexTypeID == item.rule.fAnnexTypeID);
								   let tempObj={};
								   tempObj.fPath=annexe.fPath;
								   tempObj.ruleId = item.rule.fRuleID;
								   tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
								   tempObj.fFileExName = annexe.fFileExName;
								   tempObj.fSourceFileName=annexe.fSourceFileName;							
								   this.annexEntityArrayBase=[]; 
								   this.annexEntityArrayBase.push(tempObj);
								   this.annexEntityArray.push(this.annexEntityArrayBase);								  
							   }else{
								   
							   }
							});
							result.obj.annexes.forEach((item,index)=>{
									console.log(item,index);
							})
							
						let Token = {};
						let Type = [];
						for (let item of result.obj.rules) {
							Type = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken.push(Token);
						}
						for (let i = 0; i < Type.length; i++) {
							Type[i] = '.' + Type[i]
						}
						//console.log('Type', Type);
						this.limitTypeArr = Type.toString();
						
						
					},
					rej => {}
				);
			},
			getValueByUploadRule(code) {
				return this.uploadRule.find(item => item.rule.fRuleCode == code)
			},
			//取消
			cancleForm(){
				this.$router.push({path: "/PersonalContent/InvoiceInformation"});
			},
			proceAnnexNum() {
				this.annexes = [];	
				this.nowfIsNecessary = [];
				this.annexes=this.annexes||[];
				this.annexEntityArray.forEach((p)=>{
					if(p.length>0){
						if(p[0].fPath!=""){
							let obj={};
							obj.fRuleID=p[0].ruleId;
							obj.fAnnexTypeID=p[0].fAnnexTypeID;
							obj.fPath=p[0].fPath;
							obj.fSourceFileName=p[0].fSourceFileName;
							obj.fFileExName=p[0].fFileExName;
							this.annexes.push(obj);		
							this.nowfIsNecessary.push(p[0].fAnnexTypeID);
						}
					}				
				});	
				
			},
			//保存并提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
				  if (valid) {
					this.proceAnnexNum();
					let flag = null;
					let ruleName = "";
					this.oldfIsNecessary.forEach((item1) => {
						if(!this.nowfIsNecessary.includes(item1)){
							this.uploadRule.forEach((item2)=>{
								if(item2.rule.fAnnexTypeID == item1){
									ruleName = item2.rule.fRuleName;
								}
							})
							return (flag = false);
						}
						return flag;
					});
					
					if(flag == false){
						this.$message({message: ruleName+'为必填项',type: 'warning' });
						return false;
					}
					this.ApiRequestPostNOMess('api/mall/ebcustomer/invoice-info/update', {
							  "fInvoiceInfoID": this.addCarForm.fInvoiceInfoID,
							  "fInvoiceClassID": this.addCarForm.fInvoiceClassID,
							  "fUnitName": this.addCarForm.fUnitName,
							  "fTaxAccounts": this.addCarForm.fTaxAccounts,
							  "fAddress": this.addCarForm.fAddress,
							  "fPhone": this.addCarForm.fPhone,
							  "fBankNames": this.addCarForm.fBankNames,
							  "fBankAccounts": this.addCarForm.fBankAccounts,
							  "fIsSubmit": 1,//提交
							  "annexes": this.annexes
						}).then(
						result => {
							//console.log(result);
							this.$router.push({path: "/PersonalContent/InvoiceInformation"});
						},
						rej => {}
					);
				} else {return false;}
				});
			}
		},
		filters: {
			//string转换为空
			formatString: function(val) {
				if (val === string) {
					val = '';
				}
				return val;
			}

		},
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.xinzengIcon {
		font-size: 26px;
		position: relative;
		left: 4px;
		top: 3px;
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.PersonanProfile-Number {
		font-size: 12px;
		color: #0173fe;
	}

	.aaaa {
		width: 331px;
		height: 117px;
		border-radius: 4px;
		border: 1px solid rgba(228, 228, 228, 1);
		padding: 10px 0px 0px 10px;
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-title1 {
		width: 110px;
		height: 30px;
		background-color: #0173FE;
		border-radius: 2px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		// float:right;
	}

	.newPersonbtn-Boxx {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		color: #000000;

	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-cascader {
		width: 100%;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-upload--picture-card {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 6px;
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 6px;
	}
</style>
